<template>
    <sui-table-row v-bind:selected="camera.selected" @click="selectRow">
        <sui-table-cell>
            {{camera.locationTitle}}
        </sui-table-cell>
        <sui-table-cell>
            [{{camera.cid}}] {{camera.cameraName}}
        </sui-table-cell>
        <sui-table-cell>
            {{camera.intercomNumber}}
        </sui-table-cell>
        <sui-table-cell>
            {{camera.status}}
        </sui-table-cell>
    </sui-table-row>
</template>

<script>
    export default {
        name: "CameraTableRow",
        props:{
            camera: {
                locationTitle: String,
                cameraName: String,
                intercomNumber: String,
                status: String,

                selected: Boolean
            }
        },
        methods: {
            selectRow: function () {
                this.camera.selected = !this.camera.selected
                if (this.camera.selected) {
                    this.$emit("update:selectRow", this.camera);
                } else {
                    this.$emit("update:unselectRow", this.camera);
                }
            }
        }
    }
</script>

<style scoped>
    .live-stream-button{
        padding: .3em 1em;
    }
</style>