<template>
    <sui-table-header-cell :colspan="7">
        <sui-menu pagination floated="right">
            <sui-menu-item icon link @click="previousPage">
                <sui-icon name="left chevron"></sui-icon>
            </sui-menu-item>
            <sui-menu-item v-for="p in pageItems" :key="p" link @click="selectPage(p)">{{p}}</sui-menu-item>
            <sui-menu-item icon link @click="nextPage">
                <sui-icon name="right chevron"></sui-icon>
            </sui-menu-item>
        </sui-menu>
        <sui-menu pagination floated="right">
            <sui-dropdown item placeholder="" :options="itemPerPageOptions" v-model="itemPerPage"/>
        </sui-menu>
    </sui-table-header-cell>
</template>

<script>
    export default {
        name: "Pager",
        props: {
            selectedPage: Number,
            totalItemCount: Number
        },
        computed: {
            pageItems: function () {
                let page = []
                let totalPage = Math.ceil(this.totalItemCount / this.itemPerPage)
                for (let i = 0; i < totalPage; i++) {
                    page.push(i + 1)
                }
                return page
            }
        },
        data: function () {
            const number_options = [10, 20, 50, 100]
            return {
                itemPerPage: 10,
                itemPerPageOptions: number_options.map((i) => {
                    return {
                        text: `Show ${i} entries`,
                        value: i
                    }
                })
            }
        },
        watch: {
            itemPerPage: function () {
                this.selectPage(1);
            }
        },
        methods: {
            selectPage: function (pageNumber) {
                console.log(`[Pager]select page: ${pageNumber} (${this.itemPerPage} per page)`);
                this.$emit("update:selectedPage", {
                    offset: (pageNumber - 1) * this.itemPerPage,
                    limit: this.itemPerPage,
                });
            },
            previousPage: function () {
                if (this.selectedPage - 1 > 0) {
                    this.selectPage(this.selectedPage - 1)
                }
            },
            nextPage: function () {
                let totalPage = Math.ceil(this.totalItemCount / this.itemPerPage)
                if (this.selectedPage + 1 < totalPage) {
                    this.selectPage(this.selectedPage + 1)
                }
            }
        }
    }
</script>

<style scoped>

</style>