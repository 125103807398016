<template>
    <sui-card>
        <img src="../../../assets/place-holder.png" is="sui-image"/>
        <sui-card-content>
            <sui-card-content>
                <h4 is="sui-card-header">Street 1</h4>
                <sui-card-meta>Since 3 days ago</sui-card-meta>
                <sui-card-description>
                    Description for street 1 camera
                </sui-card-description>
            </sui-card-content>
        </sui-card-content>
        <sui-card-content extra>
            <sui-icon name="star"></sui-icon>
            Bookmarked
        </sui-card-content>
    </sui-card>
</template>

<script>
    export default {
        name: "CameraBlock"
    }
</script>

<style scoped>

</style>