<template>
    <div id="camera">
        <h2 is="sui-header" class="dividing">Camera Management</h2>
        <sui-segment v-if="selectedCameras.length === 0">
            <p>Please select camera in the table to preview.</p>
        </sui-segment>
        <sui-card-group :items-per-row="3" v-if="selectedCameras.length !== 0">
            <camera-block v-for="s in selectedCameras" :key="s.id"></camera-block>
        </sui-card-group>
        <sui-divider></sui-divider>
        <sui-grid>
            <sui-grid-row>
                <sui-grid-column :width="4"></sui-grid-column>
                <sui-grid-column align="right" :width="12">
                    <sui-button size="small" :class="{hideBtn:hideAddCamera}" color="green" icon="add"
                                @click.prevent="addCamera">Add Camera
                    </sui-button>
                    <sui-button size="small" :class="{hideBtn:hideEditCamera}" :disabled="disableEditCamera"
                                color="blue" icon="edit"
                                @click.prevent="editCamera">Edit Camera
                    </sui-button>
                    <sui-button size="small" :class="{hideBtn:hideConnectCamera}" :disabled="disableConnectCamera"
                                color="violet" icon="play"
                                @click.prevent="viewCamera">Connect Camera
                    </sui-button>
                </sui-grid-column>
            </sui-grid-row>
        </sui-grid>

        <sui-divider></sui-divider>

        <sui-table celled selectable compact="">
            <sui-table-header full-width>
                <sui-table-row>
                    <sui-table-header-cell>
                        Location
                    </sui-table-header-cell>
                    <sui-table-header-cell>
                        Name
                    </sui-table-header-cell>
                    <sui-table-header-cell single-line>
                        Intercom Number
                    </sui-table-header-cell>
                    <sui-table-header-cell single-line>
                        Status
                    </sui-table-header-cell>
                </sui-table-row>
                <sui-table-row>
                    <sui-table-header-cell>
                        <sui-input placeholder="Search..." icon="filter" size="small" transparent
                                   v-model="filterLocation" @input="reloadFirstPage"/>
                    </sui-table-header-cell>
                    <sui-table-header-cell>
                        <sui-input placeholder="Search..." icon="filter" size="small" transparent v-model="filterName"
                                   @input="reloadFirstPage"/>
                    </sui-table-header-cell>
                    <sui-table-header-cell>
                        <sui-input placeholder="Search..." icon="filter" size="small" transparent
                                   v-model="filterIntercomNumber" @input="reloadFirstPage"/>
                    </sui-table-header-cell>
                    <sui-table-header-cell single-line>
                        <sui-dropdown item placeholder="" :options="optionsStatus" v-model="filterStatus"
                                      @input="reloadFirstPage"/>
                    </sui-table-header-cell>
                </sui-table-row>
            </sui-table-header>
            <sui-table-body>
                <camera-table-row v-for="c in cameras" :key="c.name" :camera="c" @update:selectRow="selectRow"
                                  @update:unselectRow="unselectRow"></camera-table-row>
            </sui-table-body>
            <sui-table-footer>
                <sui-table-row>
                    <Pager :selected-page="selectedPage" :total-item-count="totalItemCount"
                           @update:selectedPage="loadPage"></Pager>
                </sui-table-row>
            </sui-table-footer>
        </sui-table>
        <sui-divider clearing hidden></sui-divider>
    </div>
</template>

<script>

    import axios from "axios";
    import Pager from "@/components/Pager";
    import CameraTableRow from "@/views/dashboard/camera/CameraTableRow";
    import CameraBlock from "@/views/dashboard/camera/CameraBlock";

    export default {
        name: "Camera",
        components: {CameraBlock, CameraTableRow, Pager},
        data: function () {
            const status_options = ['All', 'Inactive', 'Active', 'Suspended']
            return {
                filterLocation: "",
                filterName: "",
                filterIntercomNumber: "",
                filterStatus: "",

                optionsStatus: status_options.map((i) => {
                    return {
                        text: i,
                        value: i === 'All' ? "" : i
                    }
                }),

                cameras: [],
                selectedCameras: [],

                totalItemCount: 0,
                selectedPage: 0,
                itemPerPage: 10,


                hideAddCamera: true,
                hideEditCamera: true,
                hideConnectCamera: true,

                disableEditCamera: true,
                disableConnectCamera: true,

                selectedRow: null,
            }
        },
        created: async function () {
            await this.getUserRole();
            await this.loadPage({
                limit: this.itemPerPage,
                offset: 0,
            })
        },
        methods: {
            getUserRole: async function () {
                const role = await this.$config.userRole();
                if (role === "AS") {
                    this.hideAddCamera = false;
                    this.hideEditCamera = false;
                    this.hideConnectCamera = false;
                } else if (role === "PM") {
                    this.hideAddCamera = true;
                    this.hideEditCamera = false;
                    this.hideConnectCamera = false;
                } else if (role === "BM") {
                    this.hideAddCamera = true;
                    this.hideEditCamera = false;
                    this.hideConnectCamera = false;
                } else if (role === "SI") {
                    this.hideAddCamera = true;
                    this.hideEditCamera = false;
                    this.hideConnectCamera = false;
                } else if (role === "SG") {
                    this.hideAddCamera = true;
                    this.hideEditCamera = true;
                    this.hideConnectCamera = false;
                } else {
                    this.hideAddCamera = true;
                    this.hideEditCamera = true;
                    this.hideConnectCamera = true;
                }
            },
            reloadFirstPage: async function () {
                await this.loadPage({
                    limit: this.itemPerPage,
                    offset: 0,
                })
            },
            loadPage: function (v) {
                console.log("load page");
                console.log(v);
                axios({
                    "method": "POST",
                    url: "/api/camera/list",
                    headers: {
                        "Content-Type": "application/json; charset=utf-8",
                    },
                    data: {
                        token: this.$cookies.get('token'),
                        tokenId: this.$cookies.get('tokenId'),

                        limit: v.limit,
                        offset: v.offset,

                        name: this.filterName,
                        intercomNumber: this.filterIntercomNumber,
                        status: this.filterStatus,
                        location: this.filterLocation,
                    }
                }).then((result) => {
                    const {rows, count} = result.data;
                    this.cameras = rows.map((i) => {
                        i.selected = false;
                        return i;
                    })
                    this.totalItemCount = count;
                    console.log(rows);
                }).catch((e) => {
                    console.log(e);
                    //this.$router.push('/login');
                })
            },
            selectRow: async function (row) {
                if (this.selectedRow) {
                    this.selectedRow.selected = false
                }
                this.selectedRow = row;
                this.disableEditCamera = false;
                this.disableConnectCamera = false;
            },
            unselectRow: async function () {
                this.selectedRow = null
                this.disableEditCamera = true;
                this.disableConnectCamera = true;
            },
            addCamera: function () {
                this.$router.push("/dashboard/camera/add")
            },
            editCamera: function () {
                if (this.selectedRow) {
                    this.$router.push("/dashboard/camera/edit/" + this.selectedRow.cid)
                }
            },
            viewCamera: function () {
                if (this.selectedRow) {
                    this.$router.push("/dashboard/camera/view/" + this.selectedRow.cid)
                }

            },
            rowClick: function (e) {
                console.log(e)
            }
        }

    }
</script>

<style scoped>
    tr:nth-child(2) th {
        padding: 5px 0;
    }

    tr:nth-child(2) th .ui.input {
        width: 100%;
    }

    table, thead {
        max-width: 100%;
    }

    #connectedCamera {
        margin-left: 5px;
    }

    .hideBtn {
        display: none;
    }
</style>
